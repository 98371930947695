<template>
  <div class="main">
      <enDecodeImg class="bg" v-if="appInfo['backgroundImgPC']" :src="appInfo['backgroundImgPC']" :imgStyle="{width:'100%', height:'100%'}" />
    <div class="content">
      <div class="content-left">
        <div class="content-logo">
          <enDecodeImg :src="appInfo.logo" />
        </div>
        <div class="content-appname">{{ appInfo.appName }}</div>
        <div class="content-text">请使用手机扫码二维码 下载移动客户端</div>
        <div class="qrcode">
          <div class="qrcode-img" id="qrcode-img"></div>
        </div>
      </div>
      <div class="content-right">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in appInfo.swiperImgs" :key="index">
              <enDecodeImg :key="index" :src="getImgUrl(appInfo.imgAddr, item)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import QRCode from 'qrcodejs2'
import enDecodeImg from "./img/img.vue"
import { getImgUrl } from '../utils/img'

export default {
  computed: mapState(['appInfo']),
  data() {
    return {
      getImgUrl
    }
  },
  components:{enDecodeImg},
  created() {},

  mounted() {
    new window.Swiper('.swiper-container', {
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 3000,
      },
    })

    new QRCode('qrcode-img', {
      render: 'canvas',
      text: location.href,
    })
  },

  methods: {
    getStyle() {
      return {
        backgroundImage: `url(${this.appInfo['backgroundImgPC']})`,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content {
  position: relative;
  width: 70%;
  height: 100%;
  margin: 0 auto;
}
.content-logo {
  position: absolute;
  top: 120px;
  left: 30px;
  width: 100px;
  height: 100px;
}
.content-appname {
  position: absolute;
  top: 122px;
  left: 150px;
  color: white;
  font-size: 70px;
}
.content-text {
  position: absolute;
  left: 25px;
  color: #cccccc;
  font-size: 24px;
  top: 253px;
}
.qrcode {
  width: 453px;
  height: 453px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 347px;
  left: 10px;
  border-radius: 30px;
}
.qrcode-img {
  width: 355px;
  height: 355px;
  position: relative;
  top: 49px;
  left: 49px;
}
.swiper-container {
  width: 498px;
  position: absolute;
  top: 104px;
  bottom: 0;
  right: 10px;
  overflow: hidden;
}
</style>