
/** 生成一个随机id */
export function randomElmId(prefix="eid",len=8) {
  const arr_info = [[97, 122],[48, 57]]
  let ret = prefix
  for (let i = 0; i < len; ++i) {
    const interval = arr_info[Math.floor(Math.random() * arr_info.length)]
    ret += String.fromCharCode(interval[0] + Math.floor(Math.random() * (interval[1] - interval[0] + 1)))
  }
  return ret
}

/**
 * 判断一个点是否在矩形内
 * @param {*} rect {x,y,width,height}
 * @param {*} pos {clientX,clientY}
 */
export function posInsideRect(rect,pos) {
  const {x,y,width,height} = rect
  const {clientX,clientY} = pos

  return clientX>x && clientX<x+width && clientY>y && clientY<y+height 
}