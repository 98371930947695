

/** 
 * 美术效果图 设计尺寸 (尽量与app设计尺寸一致)
 * 注意css样式宽度高度都是依据效果图尺寸
 */
var art_width = 375
/** 
 * app设计尺寸 
 */
var design_width=375
/** 设备宽度 */
var device_width = window.innerWidth || 375
/** 一屏宽度为MAX_REM */
const MAX_REM=16
/** html 最大字体大小 */
const MAX_FONT_SIZE=40
/** html 最小字体大小 */
const MIN_FONT_SIZE=20
// 1rem对应的像素值
var rem_value = calculateRemValue(device_width)
/** 显示时像素缩放比例  */
var pixel_rate = calculatePixelRate(device_width, art_width)

/** 组件是否使用rem */
var widget_use_rem = false

import classNames from "classnames"
export {classNames}

/**
 * 初始化设备分辨率信息
 * @param {*} device_width 设备宽度(1倍尺寸下的宽度)
 * @param {*} _widget_use_rem 组件是否使用rem
 * @param {*} art_width 美术效果图 设计尺寸
 */
export function initPixelDevice(_device_width,_widget_use_rem=undefined,_art_width=undefined) {
  art_width = _art_width || 375
  device_width = _device_width || window.innerWidth || 375
  widget_use_rem = _widget_use_rem || false

  rem_value = calculateRemValue(device_width)
  pixel_rate = calculatePixelRate(device_width, art_width)
}
/** 
 * 像素转换px to rem  （taro工程使用）
 * 参数像素值是以美术效果图的尺寸为标准
 * @return rem
 */
export function pxTransform (_pix) {
  /**将尺寸转换成 */
  if (typeof(_pix) === "number") {
    // 暂未实现 转换算法
    return `${(_pix/rem_value).toFixed(2)}rem`
  } else {
    return _pix
  }
}

/** 像素修正 （taro工程使用）
 * css中px会转成rem，pxCorrect会计算出实际px像素
 * @return number
 */
export function pxCorrect(_pix) {
  if (typeof(_pix) === "number") {
    return Math.floor(_pix*pixel_rate)
  } else {
    return _pix
  }
}

/** 
 * 像素转换px to rem 组件使用
 * 参数像素值是以美术效果图的尺寸为标准
 * @return rem
 */
export function pxTransformW (_pix) {
  return widget_use_rem?pxTransform(_pix):`${_pix}px`
}

/** 像素修正 组件使用
 * css中px会转成rem，pxCorrect会计算出实际px像素
 * @return number
 */
export function pxCorrectW(_pix) {
  return widget_use_rem?pxCorrect(_pix):_pix
}




/*
* @param: hex { string} 例如:"#23ff45"
* @param: opacity  { string || number } 透明度
* @return: { string } rgba格式
*/
export function hexToRgba(hex) {
  if (!hex || typeof (hex) !== 'string') return hex
  let i = 1
  const rgba = []
  while (i+2 <= hex.length) {
    rgba.push(parseInt("0x"+hex.slice(i,i+2)))
    i += 2
  }
  switch (rgba.length) {
    case 3:
      return `rgb(${rgba[0]},${rgba[1]},${rgba[2]})`
    case 4:
      return `rgba(${rgba[0]},${rgba[1]},${rgba[2]},${rgba[3]})`
    default:
      return hex
  }
}

/** 计算 1rem对应的像素值 */
function calculateRemValue(_art_width) {
  return Math.max(Math.min(MAX_FONT_SIZE,(_art_width/MAX_REM).toFixed(2)),MIN_FONT_SIZE) 
}
/** 计算像素缩放比 */
function calculatePixelRate(_device_width, _art_width) {
  const _device_width_1 = Math.max(MAX_REM*MIN_FONT_SIZE, Math.min(_device_width,MAX_REM*MAX_FONT_SIZE))
  return (_device_width_1/_art_width).toFixed(2)
}