//图片懒加载监听器
var observerForLazy=undefined
//监听回调
const observerCallback={}

/**
 * 监听节点
 * @param {*} vnode
 */
 export function observerImage(id,vnode,callback) {
	if (!id || !vnode || !callback) return
	observerCallback[id]=callback
	if (!observerForLazy) {
		observerForLazy = new IntersectionObserver(function(entries){
			entries.map(item=>{
				const key=item.target.id
				key && observerCallback[key] && observerCallback[key](item.isIntersecting)
			})
		})
		// observerForLazy = new IntersectionObserver((entries)=>{
		// 	entries.map(item=>{
		// 		const key=item.target.id
		// 		key && observerCallback[key] && observerCallback[key](item.isIntersecting)
		// 	})
		// })
	}
	observerForLazy.observe(vnode);
}

/**
 * 取消监听
 * @param {*} id
 */
export function unobserverImage(id,vnode) {
	if (!id || !observerCallback || !observerForLazy) return
	delete observerCallback[id]
	observerForLazy.unobserve(vnode);
}

/**
 * 判断点是否在矩形中
 * @param {*} x 
 * @param {*} y 
 * @param {*} left 
 * @param {*} top 
 * @param {*} width 
 * @param {*} height 
 */
export function pointInRect(x,y,left,top,right,bottom) {
	return x>=left && x<=right && y>=top && y<=bottom
}

/** 判断节点是否在可视区域内 */
export function isElInViewport(el) {
	const rect = el ? el.getBoundingClientRect() : undefined
	if (rect) {
		const innerWidth = (window.innerWidth || document.documentElement.clientWidth)
		const innerHeight = (window.innerHeight || document.documentElement.clientHeight)
		const left = parseInt(rect.left)
		const top = parseInt(rect.top)
		const right = parseInt(rect.right)
		const bottom = parseInt(rect.bottom)
		return pointInRect(left, top, 0, 0, innerWidth, innerHeight) || 
					 pointInRect(left, bottom, 0, 0, innerWidth, innerHeight) || 
					 pointInRect(right, top, 0, 0, innerWidth, innerHeight) || 
					 pointInRect(right, bottom, 0, 0, innerWidth, innerHeight)
	} else {
		return false
	}
}