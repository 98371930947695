<template>
  <div>
    <IndexMobile v-if="deviceInfo.isMobile" />
    <IndexPC v-else />
  </div>
</template>

<script>
import IndexMobile from "./index_mobile.vue"
import IndexPC from "./index_pc.vue"
import { mapState } from "vuex"

export default {
  name: "index",

  components: { IndexMobile, IndexPC },

  computed: mapState(["appInfo", "deviceInfo"]),

  methods: {
   
  },
}
</script>

<style scoped lang="scss">
</style>