<template>
  <div style="position: relative">
    <img class="mainimg" src="../assets/h5_img_landing_storevest.png" />
    <img class="logo" :src="appInfo.logo" />
    <div class="appname">{{ appInfo.appName }}</div>
    <div class="t1">本APP绝对不含任何恶意程序请放心下载</div>
    <div class="t2">
      1、点击“<span style="color: #0f6bce; font-weight: bold">下载APP</span>”后自动跳转到Apple商店下载。<br />
      2、下载后若无法进入，请点击您下载的包对应的“<span style="color: #0f6bce; font-weight: bold">复制密钥</span
      >”，并重启APP。<br />
      3、如还无法解决请卸载后重新下载。
    </div>
    <img class="logo1" :src="appInfo.majiaLogo" />
    <img class="logo2" :src="appInfo.logo" />
    <div class="appname1 font1">{{ appInfo.majiaName }}</div>
    <div class="appname2 font1">{{ appInfo.appName }}</div>
    <div class="downloadbtn btn1" @click="onDownload">下载APP</div>
    <div class="copybtn btn1" id="copycipher">复制秘钥</div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {downloadApp, ShowMsg, getQueryString} from '@/utils/common'

export default {
  computed: mapState(['appInfo', 'deviceInfo']),

  mounted() {
    var clipboard = new ClipboardJS('#copycipher', {
      text: function text() {
        return getQueryString('a')
      },
    })

    clipboard.on('success', function (e) {
      ShowMsg('复制成功')
    })
  },

  methods: {
    onDownload() {
      downloadApp(this.appInfo)
    },
  },
}
</script>

<style scoped lang="scss">
.mainimg {
  width: 100%;
}
.logo {
  width: 0.64rem;
  height: 0.64rem;
  position: absolute;
  left: 0.426rem;
  top: 0.426rem;
}
.appname {
  position: absolute;
  left: 1.28rem;
  top: 0.506rem;
  color: white;
  font-size: 0.346rem;
}
.t1 {
  font-weight: bold;
  color: #0f6bce;
  font-size: 0.4rem;
  position: absolute;
  top: 8.18rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.t2 {
  color: #666660;
  font-size: 0.373rem;
  position: absolute;
  top: 8.96rem;
  padding-left: 0.853rem;
  padding-right: 0.853rem;
  text-align: left;
  height: 2.134rem;
  overflow: scroll;
  // width: 100%;
}
.logo1 {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  top: 11.86rem;
  left: 2.48rem;
}
.logo2 {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  top: 11.86rem;
  left: 6rem;
}
.font1 {
  font-size: 0.373rem;
  color: #262626;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.appname1 {
  position: absolute;
  width: 2.24rem;
  top: 13.6rem;
  left: 2.1rem;
  text-align: center;
}
.appname2 {
  position: absolute;
  width: 2.24rem;
  top: 13.6rem;
  left: 5.68rem;
  text-align: center;
}
.downloadbtn {
  position: absolute;
  top: 15.093rem;
  background: linear-gradient(0deg, #ff6600 0%, #ffa700 100%);
  color: white;
}
.copybtn {
  position: absolute;
  top: 16.373rem;
  background-color: white;
  color: #ff6600;
  border: 1px solid #ff6600;
}
.btn1 {
  left: 1.04rem;
  right: 1.04rem;
  margin-left: auto;
  margin-right: auto;
  height: 0.96rem;
  font-size: 0.426rem;
  line-height: 0.96rem;
  padding: 0;
  border-radius: 0.6rem;
  text-align: center;
}
</style>