<template>
  <div id="app">
    <keep-alive><router-view></router-view></keep-alive>
  </div>
</template>

<script>
import mobileDetect from 'mobile-detect'
import {getQueryString, isValidBrowser, addLiquidId, getIP} from '@/utils/common'
import { getImgUrl } from './utils/img'
import { configResourceDomain } from './utils/common'

export default {
  name: 'App',
  components: {},
  methods: {
    // 资源域名配置
    configFileDomain(data) {
      configResourceDomain(data)
    }
  },
  created() {  
    let url =  '/api/open/applet/landing/queryDownloadInfo'
    let that = this
    var u = navigator.userAgent 
    let deviceInfo = {}
    deviceInfo.isAndroid = !!(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) //android终端
    deviceInfo.isiOS = !!u.match(/(iPhone|iPod|ios|iPad)/i) //ios终端
    deviceInfo.issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
    deviceInfo.isMobile = deviceInfo.isAndroid || deviceInfo.isiOS
    deviceInfo.isValidBrowser = isValidBrowser()
    window.deviceInfo = deviceInfo
    // deviceInfo.packageCode = getQueryString('channelCode')
    // deviceInfo.invitationCode = getQueryString('invitationCode')

    // 资源域名配置
    // this.configFileDomain()
    const md = new mobileDetect(u)
    let ver = ''
    if (md.os() == "iOS") {//ios系统的处理
      ver = md.versionStr("iPhone");
    } else if (md.os() == "AndroidOS") {//Android系统的处理
      ver = md.versionStr("Android");
    }
    console.log('deviceInfo',deviceInfo,navigator, process.env)
    // alert('deviceInfo=='+ JSON.stringify(deviceInfo)+';;;userAgent=='+navigator.userAgent+)
    // alert('mobileDetect=='+ JSON.stringify(md)+';;;userAgent=='+JSON.stringify(u))

    try {
      let encStr = atob(getQueryString('a'))
      // const re = /##(.*)##(.*)##/
      const re = /##(.*)##(.*)##(.*)##/
      // let encStr = '##xvuh56##111fwl##111##'
      let parseStr = encStr.match(re)
      deviceInfo.packageCode = parseStr[1]
      deviceInfo.invitationCode = parseStr[2]
      deviceInfo.appId = parseStr[3]
    } catch (error) {
      // deviceInfo.packageCode = 'll8mq9'
      // deviceInfo.invitationCode = ''
      // deviceInfo.appId = 1026
    }

    this.$store.dispatch('updateDeviceInfo', deviceInfo)
    
    getIP(function(ip){
      const params = {
        appId: deviceInfo.appId,
        channelCode: deviceInfo.packageCode,
        invitationCode: deviceInfo.invitationCode,
        deviceInfo: {
          ip: ip || '',
          // localIp: ,
          osType: md.os() == 'iOS' ? 1 : 0,
          osVersion: ver.replace(/\_/g, '.'),
          deviceWidth: window.screen.width,
          deviceHeight: window.screen.height ,
          deviceRatio: window.devicePixelRatio ,
        },
        systemType: 1
      }
      addLiquidId(params, that.configFileDomain)
      // alert('osVersion==' + md.os() + ver.replace(/\_/g, '.') + ',deviceRatio==' + window.devicePixelRatio)
      var timestamp = new Date().getTime()
  
      window.$.ajax({
        type: 'post',
        url,
        data: JSON.stringify({data: {packageCode: deviceInfo.packageCode}}),
        // dataType: 'json',
        contentType: 'application/json;charset=utf-8',
  
        headers: {
          Authorization: 'M2ZoCmm/GT12Q5ecOsxefz35aqT02+ZlzSawhaf5D5Q=',
          'X-Client-Identity': 'APP/1;com.smarter.app;1.0.0;' +  deviceInfo.packageCode + ' SDI/D4843E87-6CB4-4B4F-82C7-6A6DD02C5A84;0 SYS/IOS;12 FM/Apple;iPhone10 NE/WIFI LANG/zh-CN',
          'X-Client-TimeStamp': timestamp,
        }, 
        success: function (data) { 
          let appInfo = {
            invalid: false,
            downloadInfo: {},
            showNopage:false
          }
  
          if (!data.data) {
            appInfo.invalid = true
            that.$store.dispatch('updateAppInfo', appInfo)
            return
          }
  
          try { 
            const {logoUrl, landPageImgUrl, landPageImgUrlPc, rotationImgUrl} = data.data
            deviceInfo.appName =  data.data.appName
            appInfo['appName'] = data.data['appName']
            appInfo['appUrl'] = location.host
            appInfo['logo'] = logoUrl ? getImgUrl(data.imgAddr, logoUrl) : ''
            appInfo['backgroundImg'] = landPageImgUrl ? getImgUrl(data.imgAddr, landPageImgUrl) : ''
            appInfo['backgroundImgPC'] = landPageImgUrlPc? getImgUrl(data.imgAddr, landPageImgUrlPc) : ''
            appInfo['swiperImgs'] = rotationImgUrl ? rotationImgUrl.split(',').filter(v => v) : []
            appInfo['htmlDomainUrl'] = data.data['htmlDomainUrl']
            appInfo['imgAddr'] = data.imgAddr

            appInfo['prefix'] = data.data.prefix // 轻量包域名
            appInfo['foreign'] = data.data.foreign // 是否为海外版应用应用
            appInfo['region'] = data.data.region // 应用区域（b端配置：0-全域，1-国内，2-国外）

            document.title = window.title = data.data['appName']
            if (appInfo['swiperImgs'] && appInfo['swiperImgs'].length <= 0) {
              appInfo['swiperImgs'] = null
            }
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href =  appInfo['logo'];
            document.getElementsByTagName('head')[0].appendChild(link);
          } catch (error) {
            console.error(error, '【】')
          }
  
          for (let i = 0; i < data.data['infoList'].length; i++) {
            const info = data.data['infoList'][i]
  
            if (String(info['platFormType']) == '4' && deviceInfo.isAndroid) {
              appInfo.downloadInfo = info
              break
            } else if (String(info['platFormType']) === '3' && deviceInfo.isiOS) {
              appInfo.downloadInfo = info
              appInfo['majiaLogo'] = info['majiaLogo'] ? data['imgAddr']+info['majiaLogo'] : ''
              appInfo['majiaName'] = info['majiaName']
              appInfo['majiaCode'] = info['majiaCode']
              break
            }
          }
  
          appInfo.showNopage = appInfo.invalid || !appInfo.downloadInfo['shopDownloadUrl']
          console.log('info====',appInfo,deviceInfo, appInfo)
          that.$store.dispatch('updateAppInfo', appInfo)
        },
      })
    })
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
