<template>
  <div class="certificate">
    <img src="../assets/ld_trust_certif.png" />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {downloadApp} from '@/utils/common'

export default {
  computed: mapState(['appInfo', 'deviceInfo']),
  mounted() {
    if (window.liquidInstance) {
      downloadApp(this.appInfo)
    } else {
      this.$bus.$on('liquid_init', () => {
        downloadApp(this.appInfo)
      })
    }
  },
  methods: {},
}
</script>

<style scoped lang='scss'>
.certificate {
  width: 10rem;
  img {
    width: 10rem;
    margin: 0 auto;
  }
}
</style>