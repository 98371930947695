<template>
  <div>
    <div class="main" v-if="deviceInfo.isValidBrowser">
      <enDecodeImg class="bg" v-if="appInfo['backgroundImg']" :src="appInfo['backgroundImg']" :imgStyle="{width:'100%', height:'100%'}" />
      <div class="loading" v-if="!appInfo.appName">
        <!-- <div class="border"> 
          <div class="box"></div> 
        </div> -->
        <img class="border" src="../assets/loading.png" />
      </div>
      <template v-if="appInfo.invalid">
        <div class="invalid">
          <img src="../assets/landingpage_img_null.png" />
          <div class="invalid-title" v-if="!appInfo.invalid">系统维护中</div>
          <div class="invalid-title" v-else>渠道包code无效</div>
        </div>
      </template>
      
      <template v-else>
        <div class="tabbar">
          <div class="tabbar-logo">
            <enDecodeImg ref="logoImg" :onLoad="logoOnLoad" :onError="logoErr" v-if="appInfo.logo" class="img_logo" :src="appInfo.logo" />
          </div>
          <div class="tabbar-appname">{{ appInfo.appName }}{{ deviceInfo.isiOS && appInfo.foreign ? '-海外版' : '' }}</div>
          <!-- <div class="tabbar-appurl">{{ appInfo.appUrl }}</div> -->
          <!-- <div class="download-btn watchonline" @click="onWatchOnline">在线观看</div> -->
          <!-- <a class="download-btn" :href="appInfo.htmlDomainUrl+appInfo.downloadInfo.shopDownloadUrl" target="_blank">dwonload</a> -->
          <!-- <div class="download-btn" id="mobiledownloadbtn" @click=";">立即下载</div> -->
          <!-- <div class="download-btn" @click="onDownload">立即下载</div> -->
        </div>
        <div class="tabbar footer">
          <div class="download-btn watchonline" @click="onWatchOnline">在线观看</div>

          <!--  TODO 后续iOS证书完整之后 放开判断为 v-if="!appInfo.showNopage" -->
          <div class="download-btn" @click="onDownload" v-if="!appInfo.showNopage && deviceInfo.isAndroid">{{deviceInfo.isAndroid ? 'APK安装': 'IPA安装'}}</div>
          <div class="download-btn" @click="installLightPackege" v-if="deviceInfo.isiOS && !logoLoading">iOS轻量包</div>
        </div>
        

        <div class="invalid" v-show="!appInfo.swiperImgs && !appInfo.backgroundImg">
          <img src="../assets/landingpage_img_null.png" />
          <div class="invalid-title">你还没有配置落地页哦</div>
        </div>

        <div class="swiper-container" v-show="appInfo.swiperImgs">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item) in appInfo.swiperImgs" :key="item">
                <enDecodeImg v-if="item" :src="getImgUrl(appInfo.imgAddr, item)" />
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else>
      <img src="../assets/h5share_img_point.png" >
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {downloadApp, getQueryString, getUUID, lightPackege} from '@/utils/common'
import enDecodeImg from "./img/img"
import {getImgUrl} from '@/utils/img'
export default {
  components:{enDecodeImg},
  computed: mapState(['appInfo', 'deviceInfo']),
  data() {
    return {
      logoLoading: true,
      getImgUrl
    }
  },
  created() {
    console.log('app数据---', this.appInfo);

    window.onresize = function () {
      console.error(111)
    }
  },

  mounted() {
    // let that = this
    // var clipboard = new ClipboardJS('#mobiledownloadbtn', {
    //   text: function text() {
    //     return getQueryString('a')
    //   },
    // })

    // clipboard.on('success', function (e) {
    //   that.onDownload()
    // })
    this.logoLoading = true
  },

  beforeRouteEnter(to, from, next) {
    console.error(to, from, next)
  },
  watch: {
    appInfo(val) { 
      console.log('appInfo.swiperImgs',this.appInfo.swiperImgs)
      this.mySwiper = new window.Swiper('.swiper-container', {
        observer: true,
        observeParents: true,
        loop: true,
        autoplay: this.appInfo.swiperImgs?.length > 1
            ? {
                delay: 3000,
                disableOnInteraction: false,
              }
            : false,
      })
    },
  },

  // mounted() {
  //   // setInterval(() => {
  //   //   let x = Math.max(
  //   //     document.body.scrollHeight,
  //   //     document.documentElement.scrollHeight,
  //   //     document.body.offsetHeight,
  //   //     document.documentElement.offsetHeight,
  //   //     document.body.clientHeight,
  //   //     document.documentElement.clientHeight
  //   //   )
  //   //   if (window.x != x) {
  //   //     console.error(111)
  //   //     this.$forceUpdate()
  //   //   }
  //   //   window.x = x
  //   // }, 1000)
  // },
  updated() {
    if (this.mySwiper && this.appInfo['swiperImgs']) {
      this.$nextTick(() => {
        this.mySwiper.loopDestroy()
        this.mySwiper.loopCreate()
      })
    }
  },
  methods: {
    logoOnLoad(){
      console.log('imgOnLoad')
      this.logoLoading = false
    },
    logoErr(){
      console.log('imgErr')
      this.logoLoading = false
    },
    onWatchOnline(){
      // const tempWindow=window.open('','_blank')
      // setTimeout(() => {
      //   let payUrl = `${this.appInfo['htmlDomainUrl']}?a=${getQueryString('a')}`
      //   tempWindow.location.href=payUrl
      // }, 100);
      /** 新开窗口，在线观看ios手势会退会直接返回落地页，换成当前窗口打开 */
      window.open(`${this.appInfo['htmlDomainUrl']}?a=${getQueryString('a')}`, '_self')
    },

    getStyle() { 
      if(this.appInfo['backgroundImg']){
        return { 
          backgroundImage: `url(${this.appInfo['backgroundImg']})`,
        }
      }
      return {}
    },
    onDownload() {
      if (this.appInfo.downloadInfo && this.appInfo.downloadInfo['installType']) {
        // if (this.appInfo.showNopage) {
        //   ShowMsg('系统维护中！')
        //   return
        // }
        let installType = String(this.appInfo.downloadInfo['installType'])
        if (this.deviceInfo.isAndroid) {
          downloadApp(this.appInfo, true)
        } else if (this.deviceInfo.isiOS) {
          if (installType === '3') {
            this.$router.push({path: '/testflight', query: this.$route.query})
          } else if (installType === '1') {
            downloadApp(this.appInfo)
          } else if (installType === '4') {
            this.$router.push({path:'/installguide', query: this.$route.query})
          }
        }
      }
    },

    // iso安装轻量包
    installLightPackege(){
      // 是否为海外版轻量包，轻量包域名
      let { foreign, prefix } = this.appInfo

      const icon_src = this.$refs.logoImg.img_src
      // const url = `${this.appInfo['htmlDomainUrl']}?a=${getQueryString('a')}`
      const { appId, appName } = this.deviceInfo
      // 解析a参数，拼接轻量包类型数据
      let aStr = window.atob(getQueryString('a')) + foreign + '##'
      let endecodeA = window.btoa(aStr)
      // let a = encodeURIComponent(`${appId}$${appName}$${getQueryString('a')}`)
      let a = encodeURIComponent(`${appId}$${appName}$${endecodeA}$${prefix}`)


      // TODO:gitLab以及brand地址不支持&符号
      let url = `https://jack2030.gitlab.io/pool/${process.env.VUE_APP_ENV}/web.html?a=${a}`

      // 国外轻量包处理
      if(foreign) {
        // a = encodeURIComponent(getQueryString('a'))
        // 解析a参数
        // let aStr = window.atob(getQueryString('a')) + foreign + '##'
        
        // a = window.btoa(aStr)
        console.log('加密后的a参数----', aStr, a);
        url = `${prefix}?a=${endecodeA}`
      }

      // 16位编码数据（相同应用数值相同，不同应用数值不一样）
      let appIdStr = '33BC3CC7-18CE-4F97-9CF7-A24B608A212E'
      // 用渠道code进行替换
      let channelCode = aStr.split('##')[1].toUpperCase()
      appIdStr = appIdStr.replace(appIdStr.substring(appIdStr.length - channelCode.length), channelCode)

      console.log('加密后的a参数----', appIdStr);

      // lightPackege(icon_src, url, appName, getUUID(), foreign)
      lightPackege(icon_src, url, appName, appIdStr, foreign)
    }
  },
}
</script>

<style scoped lang="scss">
.swiper-container {
  // width: 81.6%;
  // width: 100%;
  width: 10rem;
  height: 12.312rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 10;
  overflow: visible;
  // margin-top: 200px;
}

.main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.tabbar {
  z-index: 100;
  position: fixed;
  height: 1.6rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

.tabbar-logo {
  width: 1.226rem;
  height: 1.226rem;
  position: absolute;
  left: 0.267rem;
  top: 0.187rem;
  .img_logo{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.tabbar-appname {
  position: absolute;
  top: 0.523rem;
  left: 1.76rem;
  font-size: 0.426rem;
  color: #262626;
}

.tabbar-appurl {
  color: #999999;
  font-size: 0.32rem;
  position: absolute;
  left: 1.76rem;
  top: 0.88rem;
}

.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  background: transparent;
}

.download-btn {
  z-index: 101;
  background: linear-gradient(0deg, #ff6600 0%, #ffa700 100%);
  width: 2.8rem;
  height: 0.853rem;
  // position: absolute;
  // right: 0.4rem;
  // top: 0.32rem;
  color: white;
  font-size: 0.426rem;
  line-height: 0.893rem;
  padding: 0;
  border-radius: 0.6rem;
  text-align: center;
  margin: 0 12px;
}

.watchonline{
  right: 3rem;
  background: rgba(255,255,255,.7) !important;
  color: #FF6700;
  border: 1px solid #FF6700;
}

.invalid {
  margin-top: 4.53rem;

  .invalid-title {
    position: absolute;
    top: 12rem;
    width: 100%;
    color: #999999;
    font-size: 0.426rem;
  }
}

.loading{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(3, 18, 46, 0.3);
  z-index: 500;
  text-align: center;
  .border {
    width: 100px;
    margin-top: 50%;
    animation: rotate 2s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform-origin: center center;
    transform: rotate(0deg);
  }

  to {
    transform-origin: center center;
    transform: rotate(360deg);
  }
}
</style>