/*
 * @Author: your name
 * @Date: 2022-03-23 11:48:36
 * @LastEditTime: 2022-03-23 12:47:12
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /business/Users/lion/Desktop/Git/landing/src/main.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import VueRouter from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import TestFlight from '@/components/testflight'
import Index from '@/components/index'
import Certificate from '@/components/certificate'
import InstallGuide from '@/components/installguide'

var eventBus = {
  install(Vue,options){
    Vue.prototype.$bus = new Vue()
  }
}

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(eventBus)

const store = new Vuex.Store({
  state: {
    appInfo: {
    },
    deviceInfo: {}
  },
  mutations: {
    setAppInfo(state, info) {
      state.appInfo = info
    },
    setDeviceInfo(state, info) {
      state.deviceInfo = info
    }
  },
  actions: {
    updateAppInfo(context, appInfo) {
      context.commit('setAppInfo', appInfo)
    },
    updateDeviceInfo(context, deviceInfo) {
      context.commit('setDeviceInfo', deviceInfo)
    }
  }
})

const routes = [
  { path: '/', component: Index },
  { path: '/testflight', component: TestFlight },
  { path: '/certificate', component: Certificate },
  { path: '/installguide', component: InstallGuide },
  { path: '/foo', component: HelloWorld }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
