import {downFile} from "./http"

/** 图片配置 */
const img_config = {
  /** 是否开启js图片解密功能 */
  web_encode_img :true,
  /** 开启懒加载功能 */
  use_lazy: true,
  /** 小程序图片解密worker */
  applet_work: "/wocker_div",
  /** 小程序图片非解密worker */
  applet_work_none: "/wocker_div_none",
  /** 正则 是否已经添加tupworker */
  applet_work_reg: "^/wocker_div",
  // 正则 加密域名判断
  // url_reg: "^http(s?)://([a-zA-Z0-9]+(\\\.\|-))?ssl((\\\.)?[a-zA-Z0-9\-]+)+/",
  // 正则 加密域名判断 ssl- ssl. xx-ssl- xx.ssl. xx-ssl. xx.ssl- 
  // url_reg: "^http(s?)://([a-zA-Z0-9\-]+(\\\.[a-zA-Z0-9\-]+)*(\\\.|-))?ssl(\\\.|-)",
  url_reg: "^http(s?)://([a-zA-Z0-9\-]+(\\\.[a-zA-Z0-9\-]+)*(\\\.|-))?ssl",
  // 开发模式直接将加密域名换成未加密域名
  url_reg_repalce_dev: "http$1://",
  // 加密域名特殊桶未加密
  url_reg_sepcial: ["^dev"],
  // 不能加密的后缀 正则
  no_encode_reg: ".svga?$",
}

/** 获取配置信息 */
export function getConfig(key) {
  return key?img_config[key]:img_config
}
/** 设置配置信息 */
export function setImgConfig(params={}) {
  Object.assign(img_config,params)
}

/** 判断是否是加密域名 */
export function isEncodeUrl(url) {
  if (/^http(s?):\/\//.test(url)) {
    const encrypt_domain = url.match(new RegExp(getConfig("url_reg")))
    if (encrypt_domain && !(new RegExp(getConfig("no_encode_reg")).test(url))) {
      // 注意加密域名中有特殊桶是未加密的
      // 取出域名后的路径
      const url_path = url.slice(encrypt_domain[0].length)
      const sepcial_reg = getConfig("url_reg_sepcial")
      for (let k in sepcial_reg) {
        if (new RegExp(sepcial_reg[k]).test(url_path)) {
          return false
        }
      }
      return true
    }
  }
  return false
}

/**
 * 下载加密图片 
 * resolve undefine 则是没有解密方法
 * catch 是加密图片下载失败 
 * @param {*} url 
 */
export function downloadEncodeImg(url) {
  if (window.fetchAndDec) {
    return window.fetchAndDec(url).then(content=>{
      return content?`data:image/${suffix(url)};base64,${content}`:Promise.reject("下载加密图片失败")
    })
  } else {
    return Promise.resolve(undefined)
  }
}

/** 取后缀 */
export function  suffix(url) {
  if (!url) return ""
  const arr = url.split(".")
  const _suffix=arr[arr.length-1]
  switch (_suffix) {
    case "jpeg":
      return "jpg"
    default:
      return _suffix
  }
}

/**
 * 下载图片
 * @param {*} url 
 */
export function downloadImg(url) {
  if (isEncodeUrl(url)) {
    return downloadEncodeImg(url)
  } else {
    return downFile(url,{
      responseType:'arraybuffer'
    }).then(res=>{
      return  `data:image/${suffix(url)};base64,` + btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte),''));
    })
  }
}



/**
 * 图片影视等路径拼接
 * @param prefix  资源前缀
 * @param url     资源地址
 */
export function getImgUrl(prefix, url) {
  if (url === 'nullnull') {
    return ''
  }
  if (!url || /^http/.test(url) || url.indexOf('data:image') !== -1) {
    return url
  } else {
    // return `${prefix}${/^\//.test(url) ? "" : "/"}${url}`
    return getResourceUrl(url)
  }
}

// 获取资源域名(图片、视频、文本等)
function getResourceUrl(url) {
  let result = ''
  let fileDomainConfigStr = window.localStorage.getItem('fileDomainConfig')
  
  if(fileDomainConfigStr) {
    let fileDomainConfig = JSON.parse(fileDomainConfigStr)
    // 桶路径数据
    let bucketKeyArr = []
    // 文件类型对应的配置key（默认为图片）
    let typeKey = 'img_encry_addr'
    if(/.jpg$/.test(url) || /.jpeg$/.test(url) || /.png$/.test(url) || /.gif$/.test(url) || /.svg$/.test(url)) {
      // 图片路径
      bucketKeyArr = Object.keys(fileDomainConfig.img_encry_addr)
    }else if(/.txt$/.test(url)) {
      // 文本路径
      bucketKeyArr = Object.keys(fileDomainConfig.txt_encry_addr)
      typeKey = 'txt_encry_addr'
    }else {
      // 视频、音频路径
      bucketKeyArr = Object.keys(fileDomainConfig.video_encry_addr)
      typeKey = 'video_encry_addr'
    }

    if(bucketKeyArr.length) {
      bucketKeyArr.some(item => {
        if(url.indexOf(item) !== -1) {
          // 替换掉虚拟桶路径，使用域名中的真实路径，否则会渲染失败
          let tempUrl = url.replace(item, '')
          tempUrl = tempUrl.replace(/^\/\//, '')
          result = `${fileDomainConfig[typeKey][item]}${/^\//.test(tempUrl) ? "" : "/"}${tempUrl}`
          return true
        }
      })
      
      // 没有找到对应的桶路径，则使用默认的域名
      if(!result) {
        result = `${fileDomainConfig[typeKey].default}${/^\//.test(url) ? "" : "/"}${url}`
      }
    }else {
      result = url
    }
  }

  // 没有获取到配置，则使用url
  if(!result) {
    result = url
  }

  console.log('域名缓存数据----', fileDomainConfigStr, result);

  return result
}