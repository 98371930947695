<template>
  <!--
    使用iconfont时 src必须是 [icon_开头]
  -->
  <div class="cjimg" ref="cjimgRef" :id="imgId" @click="onImgClicked">
    <div v-if="is_appear && is_svga" class="cjimg-svg" />
    <svg v-else-if="is_appear && is_font && iconFontSvg" class="icon cjimg-icon-font-svg" aria-hidden="true" :style="iconFontStyle()">
      <use :xlink:href="`#${img_src}`"></use>
    </svg>
    <span v-else-if="is_appear && is_font && !iconFontSvg" :class="`cjimg-icon-font ${img_src}`" :style="iconFontStyle()" />
    <img ref="imgRef" v-else-if="is_appear && !!img_src && isCrossOrigin" :class="imgClass" crossorigin="anonymous" :src="img_src" :style="{ objectFit: objectFit }" @load="onImgLoad($event, isCurError)" @error="onImgError($event, isCurError)" />
    <img ref="imgRef" v-else-if="is_appear && !!img_src && !isCrossOrigin" :class="imgClass" :src="img_src" :style="{ objectFit: objectFit }" @load="onImgLoad($event, isCurError)" @error="onImgError($event, isCurError)" />
    <slot name="slot-holder" v-else-if="is_appear && isShowHolderSlot" v-bind:isloading="isShowLoading" />
    <slot v-if="is_appear" />
  </div>
</template>

<script>
import { classNames } from '../../utils/style'
import './img.scss'
import './image_dec/dec_img'
import { observerImage, unobserverImage, isElInViewport } from '../../utils/elm_observer'
import { getConfig, isEncodeUrl, downloadEncodeImg } from '../../utils/img'
import { randomElmId } from '../../utils/index'
import { isNil, get } from '@xy/core'

const STEP = {
  LOADING: 1,
  SHOW: 2,
  ERROR: 3
}

export default {
  /** 组件加载后使用的名字 */
  name: 'cjimg',
  /** 引用组件 */
  components: {},
  created() {
    /** img模块是否有懒加载功能 */
    const use_lazy_func = getConfig('use_lazy')
    const _is_appear = !(use_lazy_func && (this.lazy || this.lazy === ''))
    this.initHolderImg(this.img_holder, '')
    _is_appear && this.refreshImage(this.src, _is_appear)
    this.is_appear = _is_appear
  },
  mounted() {
    // 判断是否存在跨域图片
    if(this.isCrossOrigin) {
      // console.log('小说跨域了---', this.isCrossOrigin);
      let that = this
      let tempImg = new Image();
      tempImg.crossOrigin = 'anonymous'
      // tempImg.src = this.img_src + '?' + Math.floor(Math.random() * 90000) + 10000;
      tempImg.src = this.img_src

      tempImg.onload = ()=> {   
        if(that.$refs.imgRef && that.$refs.cjimgRef) {
          // 图片宽度
          // let imgWidth = this.$refs.imgRef.clientWidth
          let imgWidth = tempImg.width
          // 图片高度
          // let imgHeight = this.$refs.imgRef.clientHeight
          let imgHeight = tempImg.height
      
          // console.log('启动页图片宽高---', imgWidth, imgHeight);
          
          // 父容器宽高
          let boxWidth = that.$refs.cjimgRef.clientWidth
          let boxHeight = that.$refs.cjimgRef.clientHeight
  
          if(imgWidth < imgHeight) {
            // 父容宽度小于高度时则根据长宽比来进行设置
            if(boxWidth < boxHeight && (boxHeight / boxWidth > imgHeight / imgWidth)) {
              // 父容器长宽比更多，则使用高度铺满
              that.$refs.imgRef.classList.add('cjimg-img-fill-height')
            }else {
              // 使用宽度铺满样式
              that.$refs.imgRef.classList.add('cjimg-img-fill-width')
            }
          }else if(imgWidth > imgHeight) {
             // 父容宽度大于高度时则根据长宽比来进行设置
            if(boxWidth > boxHeight && (boxWidth / boxHeight > imgWidth / imgHeight)) {
              // 使用宽度铺满样式
              that.$refs.imgRef.classList.add('cjimg-img-fill-width')
            }else {
              // 使用高度铺满样式
              that.$refs.imgRef.classList.add('cjimg-img-fill-height')
            }
          }else {
            // 图片宽高相等，则根据父容器宽高来处理
            if(boxWidth >= boxHeight) {
              // 使用宽度铺满样式
              that.$refs.imgRef.classList.add('cjimg-img-fill-width')
            }else {
              // 使用高度铺满样式
              that.$refs.imgRef.classList.add('cjimg-img-fill-height')
            }
          }
        }
      }
      
    }

    if (!this.is_appear && this.$el) {
      if (isElInViewport(this.$el)) {
        this.refreshImage(this.src, true)
        this.is_appear = true
      } else {
        this.is_observer_img = true
        observerImage(this.imgId, this.$el, this.appearChange.bind(this))
      }
    }
  },
  /** 销毁之前调用 */
  beforeDestroy() {
    this.unobserverImage()
  },
  /** 销毁 */
  destroyed() {
    this.clearPlaySvga()
  },
  props: {
    /** 使用iconfont时有效 */
    iconFontSize: {
      type: Number,
      default: 40
    },
    /** 是否是fontSvg */
    iconFontSvg: {
      type: Boolean,
      default: false
    },
    /** 使用iconfont时有效 */
    holderIconFontSize: {
      type: Number,
      default: undefined
    },
    /** img地址 */
    src: String,
    /** 复杂的占位图 使用外层slot处理 */
    use_holder_slot: {
      type: Boolean,
      default: false
    },
    /** img占位图 如果不需要loading占位图传入空字符串*/
    img_holder: {
      type: String,
      default: ""
    },
    /** img错误占位图 */
    img_error: {
      type: String,
      default: ""
    },
    /** img大小自动适应 */
    auto: {
      type: Boolean,
      default: false
    },
    /** id */
    id: String,
    /** 适配方案 */
    objectFit: {
      type: String,
      default: 'cover'
    },
    /** 懒加载 */
    lazy: String,
    /** 点击事件 */
    // onClick: Function | undefined,
    // /** 加载 */
    onLoad: [Function, undefined],
    // /** 出错 */
    onError: [Function, undefined],
    // 是否是启动页
    startPage: {
      type: Boolean,
      default: false
    },
    // 是否需要跨域
    isCrossOrigin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      /** 当前是否在显示区域内 */
      is_appear: undefined,
      /** 图片缓存 */
      cache: {},
      /** 图片缓存key */
      img_md5_key: undefined,
      /** 步骤 */
      step: STEP.LOADING,
      /** svga播放器 */
      svga_player: undefined,
      /** svga解析器 */
      svga_parser: undefined,
      /** 是否是svga */
      is_svga: false,
      /** 是否是字体 */
      is_font: false,
      /** 图片链接 内容 字体名 占位图 */
      img_src: undefined,
      // 图片链接（base64）
      // img_src_base64: undefined,
    }
  },
  computed: {
    /** 图片id */
    imgId() {
      return this.id || randomElmId('cjimg-', 16)
    },
    /**img样式 */
    imgClass: function() {
      return classNames({ 'cjimg-img-fill': !this.auto })
    },
    /** 当前是否是显示错误状态 */
    isCurError() {
      return this.step === STEP.ERROR
    },
    /** 当前是否是显示正常状态 */
    isShowNormal() {
      return this.step === STEP.SHOW
    },
    /** 当前是否是显示loading状态 */
    isShowLoading() {
      return this.step === STEP.LOADING
    },
    /** 是否显示holder slot */
    isShowHolderSlot() {
      return this.use_holder_slot
    }
  },
  methods: {
    ///////////////////功能函数////////////////////////////
    /** 判断是否js需要解密图片 */
    isNeedDecImg(url) {
      return !window.wx && !this.isLocalRes(url) && getConfig('web_encode_img') && isEncodeUrl(url)
    },
    /** md5 */
    md5(str) {
      return window.CryptoJS ? window.CryptoJS.MD5(str).toString() : str
    },
    /** 判断是否是svg */
    isSvga(url) {
      return typeof url === 'string' && /\.svga$/.test(url)
    },
    /** 判断是否是iconFont */
    isIconFont(url) {
      return this.isLocalRes(url) && !this.isBase64(url) && /^icon_(.)*/.test(url)
    },
    /** 是否是base64图片内容数据 */
    isBase64(url) {
      return this.isLocalRes(url) && /^data:image\//.test(url)
    },
    /** 是否是本地资源 */
    isLocalRes(url) {
      switch (typeof url) {
        case 'string':
          return !/^http/.test(url)
        default:
          return true
      }
    },
    /** iconfont样式 */
    iconFontStyle() {
      switch (this.step) {
        case STEP.LOADING:
        case STEP.ERROR:
          return { fontSize: `${this.holderIconFontSize || this.iconFontSize}px` }
        default:
          return { fontSize: `${this.iconFontSize}px` }
      }
    },
    /** 小程序使用work线程 */
    workUrl(url) {
      if (this.isLocalRes(url)) {
        return url
      } else {
        const re_text = new RegExp(getConfig('applet_work_reg'))
        // 启动页允许使用线上图片
        if (!re_text.test(url) && !this.startPage) {
          return getConfig(isEncodeUrl(url) ? 'applet_work' : 'applet_work_none') + '?url=' + url
        } else {
          return url
        }
      }
    },
    /** 本地资源传入可能是import对象 */
    getSrc(src) {
      switch (typeof src) {
        case 'object':
          return src.default || src
        default:
          return src
      }
    },
    ////////////////////////回调事件处理///////////////////////////////
    /** 点击图片 */
    onImgClicked(e) {
      if (this.step !== STEP.SHOW) return

      if (this.onClick) {
        this.onClick(e)
      } else {
        this.$emit('click', e)
      }
      // this.onClick && this.onClick(e)
      // this.$emit('click', e)
    },
    /** 图片加载完成 */
    onImgLoad(e, is_error_step) {
      !is_error_step && this.onLoad && this.onLoad(e)
    },
    /** 加载图片出错 */
    onImgError(e, is_error_step) {
      /** 如果是加载holder图片出错时显示默认holder图片 */
      // this.initHolderImg(!is_error_step ? this.img_error : undefined, I_PHOTO_FAIL)
      this.img_md5_key = undefined
      this.step = STEP.ERROR
      !is_error_step && this.onError && this.onError(e)
    },
    ///////////////////////////业务流程///////////////////////////
    /** 刷新图片 */
    refreshImage(url, _is_appear) {
      /** 如果上次图片是svga则清除svga数据 */
      if (this.is_svga) {
        this.clearPlaySvga()
      }

      if (!url) {
        this.img_md5_key = undefined
        this.initHolderImg(this.img_holder, '')
        return
      }

      this.is_svga = this.isSvga(url)
      if (this.is_svga) {
        this.img_md5_key = undefined
        this.playSvga(url)
        return
      }

      /** 分析是否需要解密 */
      if (window.wx && !this.isLocalRes(url)) {
        /** 小程序模式 并且不是使用本地资源 */
        this.img_md5_key = undefined
        this.calculateSrc({ url: this.workUrl(url) })
      } else {
        if (this.isNeedDecImg(url)) {
          /** 需要解密url */
          if (_is_appear) {
            /** 当前显示 */
            const img_key = this.md5(url)
            if (!this.img_md5_key || this.img_md5_key != img_key) {
              if (this.cache[img_key]) {
                this.img_md5_key = img_key
                this.calculateSrc({ img_key })
              } else {
                this.requestEncodeImage(url)
              }
            }
          }
        } else {
          /** 使用本地资源或者是无需解密的网络图片 */
          this.img_md5_key = undefined
          this.calculateSrc({ url })
        }
      }
    },

    /** 请求加密数据并解密 */
    requestEncodeImage(url) {
      downloadEncodeImg(url)
        .then((res) => {
          if (res) {
            this.img_md5_key = this.md5(url)
            this.cache[this.img_md5_key] = res
            this.calculateSrc({ img_key: this.img_md5_key })
            this.$forceUpdate()
          } else {
            /** 切换到未加密图片地址 */
            const re_text = new RegExp(getConfig('url_reg'))
            const dev_url = url.replace(re_text, getConfig('url_reg_repalce_dev'))
            this.calculateSrc({ url: dev_url })
          }
        })
        .catch((err) => {
          this.onImgError(err, this.isCurError)
        })
    },
    /** 取消监听 */
    unobserverImage() {
      if (this.is_observer_img) {
        unobserverImage(this.imgId, this.$vnode.elm)
        this.is_observer_img = undefined
      }
    },
    /** 节点隐藏还是显示 */
    appearChange(_is_appear) {
      /** 刷新图片就取消监听 */
      if (_is_appear) {
        this.unobserverImage()
        this.refreshImage(this.src, _is_appear)
        this.is_appear = _is_appear
      }
    },
    /** 计算当前显示的src值
     * @params {url,img_key,is_font}
     */
    calculateSrc(params) {
      const { url, img_key, is_font } = params
      this.is_font = url ? this.isIconFont(url) : false
      this.img_src = img_key ? this.cache[img_key] : url
      this.step = STEP.SHOW

      // 计算图片base64地址
      // if(this.toBase64) {
        // this.convertUrlToBase64(this.img_src)
      // }
    },
    /** holder内容 */
    initHolderImg(_holder, default_src = I_PHOTO) {
      if (this.isShowHolderSlot) {
        this.is_svga = false
        this.is_font = false
        this.img_src = undefined
      } else {
        const vaule = isNil(_holder) ? default_src : this.getSrc(_holder)
        if (vaule && typeof vaule === 'string') {
          this.is_svga = this.isSvga(vaule)
          this.is_font = this.isIconFont(vaule)
        } else {
          this.is_svga = false
          this.is_font = false
        }
        this.img_src = vaule
        // 计算图片base64地址
        // if(this.toBase64) {
          // this.convertUrlToBase64(this.img_src)
        // }
      }
    },
    /**  清除svga */
    clearPlaySvga() {
      if (this.svga_player) {
        this.svga_player.stopAnimation()
        const parentNode = get(this.svga_player, '_drawingCanvas.parentNode')
        parentNode && parentNode.removeChild(this.svga_player._drawingCanvas)
        this.step = STEP.LOADING
        this.svga_player = undefined
        this.svga_parser = undefined
      }
    },
    /** 播放svga */
    playSvga(url) {
      if (window.SVGA) {
        if (!this.svga_player) {
          const el = this.$el
          if (el) {
            this.svga_player = new window.SVGA.Player(el)
            this.svga_parser = new window.SVGA.Parser(el)
          }
        }
        const that = this
        // 注意，这里必须是服务器地址，否则报错
        this.svga_player &&
          this.svga_parser.load(
            url,
            (videoItem) => {
              /** success */
              this.step = STEP.SHOW
              that.svga_player.loops = -1
              that.svga_player.clearsAfterStop = false
              that.svga_player.setVideoItem(videoItem)
              that.svga_player.setContentMode('AspectFill')
              that.svga_player.startAnimation()
            },
            (err) => {
              this.clearPlaySvga()
              that.onImgError(err, this.isCurError)
            }
          )
      }
    },

    // 图片转base64
    convertUrlToBase64(url) {
      let that = this
      // let result = ''
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.onload =  ()=> {
          let canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          let ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, img.width, img.height);
          // let ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
          // let dataURL = canvas.toDataURL('image/' + ext);
          // let base64 = {
          //     dataURL: dataURL,
          //     type: 'image/' + ext,
          //     ext: ext
          // };
          let dataURL = canvas.toDataURL('image/');
          // result = dataURL
          that.img_src_base64 = dataURL
          console.log('base64图片数据---', dataURL);
      }
      // return result
    }
  },
  watch: {
    src: function(new_val, old_val) {
      // Log.info(`CJImg watch src. new[${new_val}],old[${old_val}]`)
      if (new_val != old_val) {
        // this.icon_err_text="photo"
        this.refreshImage(new_val, this.is_appear, this.startPage, '图片还是icon')
      }
    }
  },
  /** 安装 */
  install(vue, options) {
    vue.component(this.name, this)
  },
  /** vue use */
  use(vue) {
    if (this.name) {
      if (vue.use && this.install) {
        vue.use(this)
      } else {
        Log.error(`组件[${this.name}]安装失败`)
      }
    } else {
      Log.error('组件未设置名字[小写字母和-组成]')
    }
  }
}
</script>
