<template>
  <div style="position: relative">
    <img class="mainimg" src="../assets/ld_install_instr.png" />
    <img class="logo" :src="appInfo.logo" />
    <div class="appname">{{ appInfo.appName }}</div>
    <div class="btn download-testflight" @click="onDownloadTestflight">下载TestFlight</div>
    <img class="logo1" :src="appInfo.logo" />
    <div class="appname1">{{ appInfo.appName }}</div>
    <div class="btn download-app" @click="onDownloadApp()">下载APP</div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {downloadApp} from '@/utils/common'

export default {
  computed: mapState(['appInfo', 'deviceInfo']),

  mounted(){
    
  },

  methods: {
    onDownloadTestflight() {
      window.open('https://apps.apple.com/cn/app/testflight/id899247664', '_blank')
    },
    onDownloadApp() {
      downloadApp(this.appInfo)
    },
  },
}
</script>

<style scoped lang="scss">
.mainimg {
  width: 100%;
}
.logo {
  width: 0.64rem;
  height: 0.64rem;
  position: absolute;
  left: 0.426rem;
  top: 0.426rem;
}
.logo1 {
  width: 2.08rem;
  height: 2.08rem;
  position: absolute;
  left: 2.106rem;
  top: 22.48rem;
}
.appname {
  position: absolute;
  left: 1.28rem;
  top: 0.506rem;
  color: white;
  font-size: 0.346rem;
}
.appname1 {
  position: absolute;
  left: 4.373rem;
  top: 22.48rem;
  color: #262626;
  font-size: 0.4rem;
}
.btn {
  background: linear-gradient(0deg, #ff6600 0%, #ffa700 100%);
  width: 8rem;
  height: 0.96rem;
  position: absolute;
  color: white;
  font-size: 0.426rem;
  line-height: 0.96rem;
  padding: 0;
  border-radius: 0.6rem;
  text-align: center;
  left: 1.04rem;
}
.download-testflight {
  top: 16.13rem;
}
.download-app {
  top: 25.52rem;
}
</style>